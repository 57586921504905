import img1 from "../../../images/kollage-backgrounds/anomaly.jpg"
import img2 from "../../../images/kollage-backgrounds/annie-spratt.jpg"
import img3 from "../../../images/kollage-backgrounds/antoine-julien.jpg"
import img4 from "../../../images/kollage-backgrounds/dan-musat.jpg"
import img5 from "../../../images/kollage-backgrounds/darren-nunis.jpg"
import img6 from "../../../images/kollage-backgrounds/diana-polekhina.jpg"
import img7 from "../../../images/kollage-backgrounds/freestocks.jpg"
import img8 from "../../../images/kollage-backgrounds/joanna-kosinska.jpg"
import img9 from "../../../images/kollage-backgrounds/marek-piwnicki.jpg"
import img10 from "../../../images/kollage-backgrounds/nasa.jpg"
import img11 from "../../../images/kollage-backgrounds/scott-webb.jpg"
import img12 from "../../../images/kollage-backgrounds/sean-sinclair.jpg"

export const imageArray = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
]
