export const colorStyleMap = {
  cyan: {
    color: "rgba(0, 110, 106, 1.0)",
  },
  red: {
    color: "rgba(229, 22, 82, 1.0)",
  },
  yellow: {
    color: "rgba(254, 205, 28, 1.0)",
  },
  ligthblue: {
    color: "rgba(178, 223, 241, 1.0)",
  },
  blue: {
    color: "rgba(47, 41, 132, 1.0)",
  },
  darkblue: {
    color: "rgba(60, 106, 129, 1.0)",
  },
  pink: {
    color: "rgba(181, 13, 125, 1.0)",
  },
  lightcyan: {
    color: "rgba(191, 225, 218, 1.0)",
  },
  magenta: {
    color: "rgba(230, 207, 230, 1.0)",
  },
  lightred: {
    color: "rgba(247, 192, 195, 1.0)",
  },
  grayblue: {
    color: "rgba(215, 213, 226, 1.0)",
  },
  darkpink: {
    color: "rgba(236, 100, 126 ,1.0)",
  },
}

export const COLORS = [
  { label: "Cyan", style: "cyan" },
  { label: "Blue", style: "blue" },
  { label: "Red", style: "red" },
  { label: "Darkblue", style: "darkblue" },
  { label: "Yellow", style: "yellow" },
  { label: "Pink", style: "pink" },
  { label: "Magenta", style: "magenta" },
  { label: "Ligthblue", style: "ligthblue" },
  { label: "Lightcyan", style: "lightcyan" },
  { label: "Lightred", style: "lightred" },
  { label: "Grayblue", style: "grayblue" },
  { label: "Darkpink", style: "darkpink" },
]
