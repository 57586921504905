import React from "react"
import { Helmet } from "react-helmet"
import Editor from "../../../../components/kollage/molecules/editor"
const logo = require("../../../../images/icons/dp-logo-small-white.svg")

import "../../../../scss/site.scss"

export default function collage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Kollage | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/demokratiska-samtal/skapa-demokratiska-samtal/kollage"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/demokratiska-samtal/skapa-demokratiska-samtal/kollage"
        ></meta>
        <meta property="og:title" content="Kollage"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="page">
        <div className="logo-container">
          <img className="logo" src={logo} />
        </div>
        <Editor />
      </div>
    </>
  )
}
