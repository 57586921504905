import React from "react"
import closeIcon from "../../../images/icons/dp-close.svg"

export default function overlayInstructions({ onClick, wordlist }) {
  return (
    <div className="page-overlay">
      <div className="page-overlay_content">
        <button className="close_button" onClick={onClick}>
          <img src={closeIcon} />
        </button>
        {wordlist && (
          <>
            <h5 className="page-overlay_content_title">
              Vänligen ta bort följande ord
            </h5>
            <ul className="page-overlay_content_list">
              {wordlist.map((word, i) => (
                <li key={i} className="page-overlay_content_list_item">
                  {word}
                </li>
              ))}
            </ul>
          </>
        )}
        {!wordlist && (
          <>
            <h5 className="page-overlay_content_title">
              Skapa demokratiska samtal
            </h5>
            <p>
              Vad gör ett samtal bra? <br />
              Skriv dina tips här och gör ett kollage.
            </p>
          </>
        )}
      </div>
    </div>
  )
}
