import React from "react"
import { COLORS, colorStyleMap } from "../data/colors"
import styled from "styled-components"

class StyleButton extends React.Component {
  constructor(props) {
    super(props)
    this.onToggle = e => {
      e.preventDefault()
      this.props.onToggle(this.props.style, colorStyleMap)
    }
  }

  render() {
    const { label } = this.props
    let color
    for (const [key, value] of Object.entries(colorStyleMap)) {
      if (key === label.toLowerCase()) color = value.color
    }
    return <ColorSpan onMouseDown={this.onToggle} color={color} />
  }
}

export default function ColorControls(props) {
  var currentStyle = props.editorState.getCurrentInlineStyle()
  return (
    <div className="color-controls">
      {COLORS.map((type, i) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

const ColorSpan = styled.span`
  background-color: ${props => props.color};
  width: 104px;
  height: 93px;
  cursor: pointer;
  display: inline-block;
  flex-basis: 33.333333%;
`
