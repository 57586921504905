import React, { useState } from "react"
import Functions from "./functions"
import { navigate } from "gatsby"

export default function saveModal({ onClick, data }) {
  const [school, setSchool] = useState("")
  const [grade, setGrade] = useState("")
  const [name, setName] = useState("")
  const [error, setError] = useState({
    school: null,
    class: null,
    general: null,
  })
  const [saved, setSaved] = useState(null)
  const functionKit = new Functions()

  const downloadImage = () => {
    const filename = `${name}_${grade}_${school}`
    const link = document.createElement("a")
    link.href = data.image
    link.download = filename
    link.click()
  }

  const handleSave = async () => {
    setError({
      school: null,
      class: null,
      general: null,
    })
    const payload = {
      class: grade,
      school: school,
      data: {
        text: data.text,
        background: data.backgroundImage
          ? data.backgroundImage
          : data.backgroundColor,
        font: data.font,
      },
      image: data.image,
    }
    console.log(payload)

    const res = await functionKit.postData(payload)
    console.log(res)

    if (res.status === 200) {
      setSaved(true)
    } else if (res.status !== 200) {
      if (res.message && res.message.field) {
        setError({
          [res.message.field]: "något gick fel",
        })
      } else {
        setError({
          general: "nånting gick fel, försök igen.",
        })
      }
    }
  }

  return (
    <div className="save-overlay page-overlay">
      {saved && (
        <div className="save-overlay__content">
          <button className="close_button" onClick={onClick}>
            X
          </button>
          <p>Ditt Kollage har publicerats på hemsidan</p>
          <div className="save-overlay__content__button-container">
            <button
              className="save-overlay__content__button-container--button"
              onClick={() =>
                `${navigate(
                  "/demokratiska-samtal/skapa-demokratiska-samtal/kollage-list/"
                )}`
              }
            >
              Kollage lista
            </button>
            <button
              className="save-overlay__content__button-container--button"
              onClick={downloadImage}
            >
              Ladda ner
            </button>
          </div>
        </div>
      )}
      {!saved && (
        <form className="save-overlay__content">
          <button className="close_button" onClick={onClick}>
            X
          </button>
          <div className="save-overlay__content__container">
            <div className="save-overlay__content__item">
              <label className="save-overlay__content__item--label col-md-4 col-xs-12">
                {" "}
                Klass{" "}
              </label>
              <div className="col-md-7 col-xs-12">
                <input
                  type="text"
                  name="grade"
                  onChange={e => setGrade(e.target.value)}
                  required
                  className="save-overlay__content__item--input"
                />
                <span className="save-overlay__content__item--error">
                  {error.class && error.class}
                </span>
              </div>
            </div>
            <div className="save-overlay__content__item">
              <label className="save-overlay__content__item--label col-md-4 col-xs-12">
                {" "}
                Skola{" "}
              </label>
              <div className="col-md-7 col-xs-12">
                <input
                  type="text"
                  name="school"
                  onChange={e => setSchool(e.target.value)}
                  required
                  className="save-overlay__content__item--input"
                />
                <span className="save-overlay__content__item--error">
                  {error.school && error.school}
                </span>
              </div>
            </div>
            <div className="save-overlay__content__item">
              <label className="save-overlay__content__item--label col-md-4 col-xs-12">
                {" "}
                Namn{" "}
              </label>
              <div className="col-md-7 col-xs-12">
                <input
                  type="text"
                  name="name"
                  onChange={e => setName(e.target.value)}
                  required
                  className="save-overlay__content__item--input"
                />
                <span className="save-overlay__content__item--info">
                  Ditt namn sparas inte till databasen
                </span>
              </div>
            </div>
            {error.general && (
              <span className="save-overlay__content__item--error">
                {error.general}
              </span>
            )}
          </div>
          <div className="save-overlay__content__button-container">
            <button
              type="button"
              onClick={handleSave}
              disabled={school && grade && name ? false : true}
              className="save-overlay__content__button-container--button"
            >
              Publicera
            </button>
            {/*<button
              type="button"
              onClick={downloadImage}
              disabled={school && grade && name ? false : true}
              className="save-overlay__content__button-container--button"
            >
              Ladda ner
            </button>*/}
          </div>
        </form>
      )}
    </div>
  )
}
