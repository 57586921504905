import React from "react"
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
} from "draft-js"
import ColorControls from "../atoms/colorStyleButton"
import SizeControls from "../atoms/fontStyleButton"
import OverlayStyles from "../atoms/overlayStyles"
import OverlayContent from "../atoms/overlayContent"
import { colorStyleMap } from "../data/colors"
import { fontSizeMap, dynamicFontSizes } from "../data/font"
import { wordList } from "../data/wordlist"
import html2canvas from "html2canvas"
import styled from "styled-components"
import Overlay from "../atoms/pageOverlay"
import Modal from "../atoms/saveModal"
import Functions from "../atoms/functions"

import bgColor from "../../../images/icons/dp-tool-bg-color.svg"
import textColor from "../../../images/icons/dp-tool-color.svg"
import bgImage from "../../../images/icons/dp-tool-image.svg"
import textBold from "../../../images/icons/dp-tool-text-bold.svg"
import textItalic from "../../../images/icons/dp-tool-text-italic.svg"
import textSize from "../../../images/icons/dp-tool-text-size.svg"
import undo from "../../../images/icons/dp-tool-undo.svg"
import redo from "../../../images/icons/dp-tool-redo.svg"
import fontIcon from "../../../images/icons/dp-tool-text.svg"

const Draft = require("draft-js")

export default class MyEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createWithContent(emptyContentState),
      blacklist: [],
      showOverlay: false,
      styleOptions: null,
      fontSize: "3.1rem",
      toggleOverlay: true,
      toggleSaveMenu: false,
      backgroundColor: "white",
      backgroundImage: null,
      font: "inherit",
      image: null,
      rawContent: null,
    }
    this.onChange = editorState => this._onChangeText(editorState)
    this.handleKeyCommand = this.handleKeyCommand.bind(this)
    this.toggleStyle = (toggledStyle, map) =>
      this._toggleStyle(toggledStyle, map)
    this.myRef = React.createRef()
    this.setDomEditorRef = ref => (this.domEditor = ref)
    this.focus = () => this.domEditor.focus()
    this.prevStackSize = 0
    this.functionKit = new Functions()
  }

  _onChangeText = editorState => {
    this.setState({ editorState })
    const textLength = editorState.getCurrentContent().getPlainText("\u0001")
      .length
    const increment = textLength / 20
    if (Math.round(increment) < dynamicFontSizes.length)
      this.setState({ fontSize: dynamicFontSizes[Math.round(increment)] })
    else
      this.setState({ fontSize: dynamicFontSizes[dynamicFontSizes.length - 1] })
  }

  componentDidMount() {
    this.domEditor.focus()
  }

  _toggleStyle = (toggledStyle, map) => {
    const { editorState, showOverlay } = this.state
    const selection = editorState.getSelection()

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(map).reduce((contentState, style) => {
      return Modifier.removeInlineStyle(contentState, selection, style)
    }, editorState.getCurrentContent())

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    )

    const currentStyle = editorState.getCurrentInlineStyle()

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, style) => {
        return RichUtils.toggleInlineStyle(state, style)
      }, nextEditorState)
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledStyle)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledStyle
      )
    }
    this.onChange(nextEditorState)
    this.setState({ showOverlay: !showOverlay })
  }

  _onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"))
  }

  _onItalicClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC"))
  }

  _onRedoClick = () => {
    this.onChange(EditorState.redo(this.state.editorState))
  }

  _onUndoClick = () => {
    this.onChange(EditorState.undo(this.state.editorState))
  }

  _onOverlayStyles = type => {
    const { showOverlay, editorState } = this.state
    this.setState({ showOverlay: !showOverlay })
    switch (type) {
      case "image":
        this.setState({
          styleOptions: (
            <OverlayStyles
              type="images"
              onToggle={e => this._onToggleBackgroundImage(e)}
            />
          ),
        })
        break
      case "color":
        this.setState({
          styleOptions: (
            <OverlayStyles
              type="colors"
              onToggle={e => this._onToggleBackgroundColor(e)}
            />
          ),
        })
        break
      case "font":
        this.setState({
          styleOptions: (
            <OverlayStyles type="font" onToggle={e => this._onToggleFont(e)} />
          ),
        })
        break
      case "textColor":
        this.setState({
          styleOptions: (
            <ColorControls
              editorState={editorState}
              onToggle={this.toggleStyle}
            />
          ),
        })
        break
      case "textSize":
        this.setState({
          styleOptions: (
            <SizeControls
              editorState={editorState}
              onToggle={this.toggleStyle}
            />
          ),
        })
        break
      default:
        break
    }
  }

  _onToggleBackgroundImage = e => {
    this.setState({
      backgroundImage: e,
      backgroundColor: null,
    })
    this.setState({ showOverlay: false })
  }

  _onToggleBackgroundColor = e => {
    this.setState({
      backgroundImage: null,
      backgroundColor: e,
    })
    this.setState({ showOverlay: false })
  }

  _onToggleFont = e => {
    this.setState({ font: e })
    this.setState({ showOverlay: false })
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return "handled"
    }
    return "not-handled"
  }

  checkContent = () => {
    let textArray = []
    let text = ""
    const editorContent = convertToRaw(
      this.state.editorState.getCurrentContent()
    ).blocks
    editorContent.map(content => (text += content.text.toLowerCase()))
    textArray.push(text.split(" "))

    console.log(textArray)
    wordList.map(word => {
      if (textArray[0].includes(word.toLowerCase())) {
        this.state.blacklist.push(word)
      }
    })

    if (this.state.blacklist.length > 0) {
      this.setState({ blacklist: this.state.blacklist })
      return true
    }
  }

  toImage = async () => {
    let disable = false
    disable = this.checkContent()
    if (!disable) {
      const node = this.myRef.current
      let scale = 1000 / this.myRef.current.clientWidth
      const canvas = await html2canvas(node, {
        scale: scale,
      })
      let image = canvas.toDataURL("image/png", 1)
      this.setState({ image: image })
    }
  }

  getText = () => {
    let text = ""
    const editorContent = convertToRaw(
      this.state.editorState.getCurrentContent()
    ).blocks
    editorContent.map(content => (text += content.text))
    this.setState({ rawContent: text })
  }

  setToggleOverlay = () => {
    this.setState({ toggleOverlay: false })
  }

  handleSave = () => {
    const disable = this.checkContent()
    if (!disable) {
      this.setState({ toggleSaveMenu: !this.state.toggleSaveMenu })
      this.toImage()
      this.getText()
    }
  }

  render() {
    const {
      editorState,
      showOverlay,
      styleOptions,
      fontSize,
      toggleOverlay,
      toggleSaveMenu,
      blacklist,
      font,
      backgroundImage,
      backgroundColor,
      image,
      rawContent,
    } = this.state

    return (
      <div>
        {toggleOverlay && <Overlay onClick={this.setToggleOverlay} />}
        {toggleSaveMenu && (
          <Modal
            onClick={() => this.setState({ toggleSaveMenu: false })}
            data={{
              image: image,
              backgroundColor: backgroundColor,
              backgroundImage: backgroundImage,
              font: font,
              text: rawContent,
            }}
          />
        )}
        {blacklist && blacklist.length > 0 && (
          <Overlay
            wordlist={blacklist}
            onClick={() => {
              this.setToggleOverlay()
              this.setState({ blacklist: [] })
            }}
          />
        )}
        <div className="controls">
          <div>
            <StyleButton
              src={fontIcon}
              onClick={() => this._onOverlayStyles("font")}
              width={24}
              height={25}
            />
            <StyleButton
              onClick={() => this._onOverlayStyles("textSize")}
              src={textSize}
              width={39}
              height={25}
            />
            <StyleButton
              onClick={this._onBoldClick.bind(this)}
              src={textBold}
              width={18}
              height={23}
            />
            <StyleButton
              onClick={this._onItalicClick.bind(this)}
              src={textItalic}
              width={16}
              height={24}
            />
            <StyleButton
              onClick={() => this._onOverlayStyles("textColor")}
              src={textColor}
              width={27}
              height={27}
            />
          </div>
          <div>
            <StyleButton
              onClick={this._onUndoClick.bind(this)}
              src={undo}
              width={25}
              height={22}
            />
            <StyleButton
              onClick={this._onRedoClick.bind(this)}
              src={redo}
              width={25}
              height={22}
            />
          </div>
          <div>
            <StyleButton
              onClick={() => this._onOverlayStyles("image")}
              src={bgImage}
              width={33}
              height={30}
            />
            <StyleButton
              onClick={() => this._onOverlayStyles("color")}
              src={bgColor}
              width={29}
              height={30}
            />
          </div>
          <div>
            <button className="button button_save" onClick={this.handleSave}>
              Spara
            </button>
          </div>
        </div>
        {showOverlay && (
          <div className="overlay">
            <OverlayContent>{styleOptions}</OverlayContent>
          </div>
        )}
        <Background
          color={backgroundColor}
          image={backgroundImage}
          ref={this.myRef}
          fontSize={fontSize}
          font={font}
          id="background"
        >
          <div onClick={this.focus} className="editorContainer">
            <Editor
              id="controls"
              customStyleMap={{ ...fontSizeMap, ...colorStyleMap }}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              placeholder="Skriv här..."
              ref={this.setDomEditorRef}
            />
          </div>
        </Background>
      </div>
    )
  }
}

const emptyContentState = Draft.convertFromRaw({
  entityMap: {},
  blocks: [
    {
      text: "",
      key: "foo",
      type: "unstyled",
      entityRanges: [],
    },
  ],
})

const Background = styled.div`
  position: absolute;
  width: 90%;
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: calc(100vh - 10rem);
  background: ${props => (props.image ? "" : props.color)};
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  font-size: ${props => props.fontSize};
  font-family: ${props => props.font};

  @media (min-width: 576px) {
    max-height: 75vh;
  }

  @media (min-width: 768px) {
    max-height: 85vh;
  }
`

const StyleButton = styled.button`
  background: url(${props => props.src});
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: none;
  outline: none;
  color: white;
  padding: 2px;
  margin: 5px;
  margin-right: 40px;
`
