import React from "react"
import { imageArray } from "../data/images.js"
import styled from "styled-components"
import { colorStyleMap } from "../data/colors"
import { FONTS } from "../data/font"

export default function BackgroundStyles({ type, onToggle }) {
  return (
    <>
      {type === "images" && (
        <div className="image-controls">
          <div className="image-controls_container">
            {imageArray.map((img, i) => {
              return (
                <img
                  src={img}
                  className="image-controls_image"
                  key={i}
                  onClick={() => onToggle(img)}
                  alt=""
                />
              )
            })}
          </div>
          <p className="image-controls_text">
            Foto: Darren Nunis, Anomaly, Diana Polekhina, Antoine Julien, Scott
            Webb, Nasa, Dan Musat, Annie Spratt, Marek Piwnicki, Sean Sinclair,
            Joanna Kosinska.
          </p>
        </div>
      )}
      {type === "colors" && (
        <div className="color-controls">
          {Object.entries(colorStyleMap).map(key => (
            <ColorSpan
              color={key[1].color}
              onClick={() => onToggle(key[1].color)}
              key={key[1].color}
            />
          ))}
        </div>
      )}
      {type === "font" && (
        <div className="font-controls">
          {FONTS.map((font, i) => (
            <div key={i}>
              <FontDiv onClick={() => onToggle(font.type)} font={font.type}>
                {font.type}
              </FontDiv>
              {i < FONTS.length - 1 && <hr />}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

const ColorSpan = styled.div`
  background-color: ${props => props.color};
  width: 104px;
  height: 93px;
  cursor: pointer;
  display: inline-block;
  flex-basis: 33.333333%;
`

const FontDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 3rem;
  padding-top: 5px;
  padding-bottom: 5px;
  color: black;
  height: auto;
  width: 100%;
  cursor: pointer;
  font-size: 2rem;
  font-family: ${props => props.font};
`
