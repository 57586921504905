export const fontSizeMap = {
  small: {
    fontSize: "1.5rem",
  },
  medium: {
    fontSize: "4rem",
  },
  large: {
    fontSize: "5rem",
  },
}

export const SIZES = [
  { label: "S", style: "small" },
  { label: "M", style: "medium" },
  { label: "L", style: "large" },
]

export const dynamicFontSizes = [
  "3.5rem",
  "3.4rem",
  "3.3rem",
  "3.2rem",
  "3.1rem",
  "3rem",
  "2.8rem",
  "2.7rem",
  "2.6rem",
  "2.5rem",
  "2.4rem",
]

export const FONTS = [
  { type: "EB Garamond" },
  { type: "Oswald" },
  { type: "Permanent Marker" },
  { type: "Bungee Shade" },
  { type: "Caveat" },
  { type: "Open Sans" },
]
