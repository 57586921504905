export const wordList = [
  "heil",
  "neger",
  "negrar",
  "zigenare",
  "blatte",
  "svartskalle",
  "kuk",
  "fitta",
  "balle",
  "snippa",
  "snopp",
  "turk",
  "bög",
  "transa",
]
