import React from "react"
import { SIZES, fontSizeMap } from "../data/font"
import styled from "styled-components"

class StyleButton extends React.Component {
  constructor(props) {
    super(props)
    this.onToggle = e => {
      e.preventDefault()
      this.props.onToggle(this.props.style, fontSizeMap)
    }
  }

  render() {
    const { style } = this.props
    let size
    for (const [key, value] of Object.entries(fontSizeMap)) {
      if (key === style.toLowerCase()) {
        size = value.fontSize.replace("rem", "")
        size = size / 2 + "rem"
      }
    }

    return (
      <FontSpan onMouseDown={this.onToggle} size={size}>
        <span>{this.props.label}</span>
      </FontSpan>
    )
  }
}

export default function SizeControls(props) {
  var currentStyle = props.editorState.getCurrentInlineStyle()
  return (
    <div className="font-size-controls">
      {SIZES.map((type, i) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          onToggle={props.onToggle}
          label={type.label}
          style={type.style}
        />
      ))}
    </div>
  )
}

const FontSpan = styled.span`
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0 1rem;
  width: 94px;
  height: 94px;
  border: 1px solid black;
  font-size: ${props => props.size};
`
